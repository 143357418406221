import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

import '../../App.scss';
import './ContactForm.scss';

export const ContactForm = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const [disabled, setDisabled] = useState(false);
    const [alertInfo, setAlertInfo] = useState({
        display: false,
        message: '',
        type: '',
    });

    // Shows alert message for form submission feedback
    const toggleAlert = (message, type) => {
        setAlertInfo({ display: true, message, type });

        // Hide alert after 5 seconds
        setTimeout(() => {
            setAlertInfo({ display: false, message: '', type: '' });
        }, 5000);
    };

    // Function called on submit that uses emailjs to send email of valid contact form
    const onSubmit = async (data) => {
        // Destrcture data object
        const { name, email, subject, message } = data;
        try {
            // Disable form while processing submission
            setDisabled(true);

            // Define template params
            const templateParams = {
                name,
                email,
                subject,
                message,
            };

            await emailjs
                .send('service_arw2aoc', 'template_z5717tf', templateParams, {
                    publicKey: 'tf2HxHONrrud2sJAF',
                })
                .then(
                    () => {
                        console.log('SUCCESS!');
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                    },
                );

            // Display success alert
            toggleAlert('Form submission was successful!', 'success');
        } catch (e) {
            console.error(e);
            // Display error alert
            toggleAlert('Uh oh. Something went wrong. Please try again.', 'danger');
        } finally {
            // Re-enable form submission
            setDisabled(false);
            // Reset contact form fields after submission
            reset();
        }
    };

    return (
        <div className='contact-form-div'>
            <iframe title='Map: Truman Health' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.1278427350594!2d-79.39503202333132!3d43.64550845292749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3539e69517a5%3A0x8a9298752739bd34!2sTruman%20Health!5e0!3m2!1sen!2sca!4v1711043004024!5m2!1sen!2sca" style={{ width: "600", height: "450", border: "0", allowfullscreen: "", loading: "lazy" }} referrerpolicy="no-referrer-when-downgrade"></iframe>
            <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className='contact-form__heading'>Get in touch</div>
                <div className='contact-form__row'>
                    <input
                        type='text'
                        name='name'
                        {...register('name', {
                            required: {
                                value: true,
                                message: 'Please enter your name',
                            },
                            maxLength: {
                                value: 30,
                                message: 'Please use 30 characters or less',
                            },
                        })}
                        placeholder='Your Name'
                        className='form-control formInput'>
                    </input>
                    {errors.name && (
                        <span className='error-message'>
                            {errors.name.message}
                        </span>
                    )}
                </div>
                <div className='contact-form__row'>
                    <input
                        type='email'
                        name='email'
                        {...register('email', {
                            required: true,
                            pattern:
                                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                        placeholder='Your Email Address'
                        className='form-control formInput'>

                    </input>
                    {errors.email && (
                        <span className='error-message'>
                            Please enter a valid email address
                        </span>
                    )}
                </div>
                <div className='contact-form__row'>
                    <input
                        type='text'
                        name='subject'
                        {...register('subject', {
                            required: {
                                value: true,
                                message: 'Please enter a subject',
                            },
                            maxLength: {
                                value: 75,
                                message: 'Subject cannot exceed 75 characters',
                            },
                        })}
                        placeholder='Subject'
                        className='form-control formInput'>
                    </input>
                    {errors.subject && (
                        <span className='error-message'>
                            {errors.subject.message}
                        </span>
                    )}
                </div>
                <div className='contact-form__row'>
                    <textarea
                        rows={3}
                        name='message'
                        {...register('message', {
                            required: {
                                value: true,
                                message: 'Please enter a message',
                            },
                        })}
                        className='form-control formInput'
                        placeholder='Message'
                    ></textarea>
                    {errors.message && (
                        <span className='error-message'>
                            {errors.message.message}
                        </span>
                    )}
                </div>
                <button
                    className='contact-form__submit'
                    disabled={disabled}
                    type='submit'>
                    SUBMIT
                </button>
            </form >
            {alertInfo.display && (
                <div
                    className={`alert alert-${alertInfo.type} alert-dismissible mt-5`}
                    role='alert'
                >
                    {alertInfo.message}
                    <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='alert'
                        aria-label='Close'
                        onClick={() =>
                            setAlertInfo({ display: false, message: '', type: '' })
                        } // Clear the alert when close button is clicked
                    ></button>
                </div>
            )}
        </div>

    );
};


export default ContactForm;