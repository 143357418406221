import React from 'react';
import '../../App.scss';
import './GeneralPages.scss';
import { Footer } from '../../components/footer/Footer';

function AvailableInCanada() {
    return (
        <>
            <div className='available-in-canada'>
                <div className='available-in-canada__heading'>
                    Where Truman Health is currently available
                </div>
                <p>Truman is a Canadian Company, and we can serve residents of Ontario.</p>
            </div>
            <Footer />
        </>
    );
}

export default AvailableInCanada;