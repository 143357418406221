import React from 'react';
import '../../App.scss';
import './PreFooter.scss';
import { Button } from '../buttons/Button';

export const PreFooter = () => {
    return (
        <>

            <div className='prefooter__services'>
                <div className='prefooter__services-images'>
                    <a class="prefooter__services-images-container" href='https://app.trumanhealth.ca/erectile-dysfunction/patient-intake?dis=erectile' target='__blank' rel="noopener noreferrer">
                        <img src={require(`../../assets/prefooter_ed_banner.jpeg`)} alt="Smiling man" />
                        <div class="prefooter__services-images-container-details">
                            <span class="title">ED</span>
                            <span class="info">Get Started</span>
                        </div>
                    </a>
                    <a class="prefooter__services-images-container" href='https://app.trumanhealth.ca/hairloss/patient-intake?dis=hairloss' target='__blank' rel="noopener noreferrer">
                        <img src={require(`../../assets/prefooter_hairloss_banner.jpeg`)} alt="Smiling man" />
                        <div class="prefooter__services-images-container-details">
                            <span class="title">Hair Loss</span>
                            <span class="info">Get Started</span>
                        </div>
                    </a>
                </div>
                <div className='prefooter__services-description'>
                    <h2>Men's health made easy.</h2>
                    <p>From your first online visit to receiving your prescription, Truman offers discreet service and free shipping.
                        Because you deserve better health.</p>
                </div>
            </div >
            <div className='prefooter__blog'>
                <div className='prefooter__blog-image'>
                    <a href='/blog' target='__blank' rel="noopener noreferrer">
                        <img src={require(`../../assets/prefooter_blog_banner.jpeg`)} alt="happy couple" />
                    </a>
                </div>
                <div className='prefooter__blog-description'>
                    <p>Blog</p>
                    <h2>Knowledge is power.</h2>
                    <Button buttonStyle='btn--primary' linkTo="/blog">Learn More</Button>
                </div>
            </div>
            <div className='prefooter__ending'>
                <div className='prefooter__ending-container'></div>
            </div>
        </>
    )
}

export default PreFooter;

