import React, { useState } from 'react';
import '../../App.scss';
import './ErectileDysfunction.scss';
import { Hero } from '../../components/hero/Hero';
import { SecondaryTextPanel } from '../../components/text_panels/TextPanels';
import { Button } from '../../components/buttons/Button';
import { HowItWorks } from '../../components/how_it_works/HowItWorks';
import { RXCard } from '../../components/rx_cards/RXCard';
import { Footer } from '../../components/footer/Footer';
import PreFooter from '../../components/prefooter/PreFooter';

function ErectileDysfunction() {
    const [button] = useState(true);
    return (
        <>
            <Hero
                heading="Better sex without the stress."
                subheading="Prescription ED medication, available online with discreet home delivery."
                bannerImage="ed_banner"
                bannerLink="https://app.trumanhealth.ca/erectile-dysfunction/patient-intake?dis=erectile" />
            <div className='rx-cards-container'>
                <RXCard
                    drugImage="rx_image"
                    drugName="Sildenafil (Generic Viagra)"
                    drugDescription="The generic form of Viagra. Highly effective in helping you achieve and maintain an erection when you need it."
                    drugUsage="As needed"
                    drugStrength="50mg, 100mg"
                    drugPrice="$13/dose"
                    cardLink="https://app.trumanhealth.ca/erectile-dysfunction/patient-intake?dis=erectile"
                />
                <RXCard
                    drugImage="rx_image"
                    drugName="Tadalafil (Generic Cialis)"
                    drugDescription="The generic form of Cialis. An effective daily option for when you don't want to think about taking a pill before sex."
                    drugUsage="As needed or daily"
                    drugStrength="5mg, 20mg"
                    drugPrice="$17/dose"
                    cardLink="https://app.trumanhealth.ca/erectile-dysfunction/patient-intake?dis=erectile"
                />
                <RXCard
                    drugImage="rx_image"
                    drugName="Vardenafil (Generic Levitra)"
                    drugDescription="The generic form of Levitra. Highly effective in helping you maintain an erection when you need it."
                    drugUsage="As needed"
                    drugStrength="5mg, 20mg"
                    drugPrice="$17/dose"
                    cardLink="https://app.trumanhealth.ca/erectile-dysfunction/patient-intake?dis=erectile"
                />
            </div>
            <div className='text-panel-container'>
                <SecondaryTextPanel
                    heading="Make getting hard easy"
                    paragraph="Almost half of men by age 40 struggle with not being able to achieve or maintain an erection. This is called Erectile Dysfunction and it's very common as men get older. Lucky for you, treatment options are available to help restore a healthy sex life."
                    buttonLabel="LET'S GET TREATED"
                    image="ed_panel_img_one"
                    altText="Phallic banana"
                    direction="left"
                    panelLink="https://app.trumanhealth.ca/erectile-dysfunction/patient-intake?dis=erectile"
                />
                <SecondaryTextPanel
                    heading="Common symptoms of ED"
                    paragraph="Not being able to achieve or maintain an erection is the most common symptom of ED. Effective treatment is available."
                    buttonLabel="I'M EXPERIENCING ED"
                    image="ed_panel_img_two"
                    altText="Man looking at pills"
                    direction="right"
                    panelLink="https://app.trumanhealth.ca/erectile-dysfunction/patient-intake?dis=erectile"
                />
                <SecondaryTextPanel
                    heading="Sildenafil, tadalafil or vardenafil?"
                    paragraph="Which medication is right for you? You can either take one pill a day, every day, that will help you to always be ready for sex (that’s tadalafil), or take a pill as-needed (that’s sildenafil or vardenafil). These prescriptions are the generic forms of Cialis, Viagra and Levitra. Whichever you choose, your player will always show up ready for game time."
                    buttonLabel="FIND MY TREATMENT"
                    image="ed_panel_img_three"
                    altText="Man smiling at screen"
                    direction="left"
                    panelLink="https://app.trumanhealth.ca/erectile-dysfunction/patient-intake?dis=erectile"
                />
            </div>
            <div className='ed-prompt'>
                <div className='ed-prompt-subsection'>
                    <h1 className='ed-prompt-heading'>Backed by Science</h1>
                    <p className='ed-prompt-subheading'>Viagra and Cialis have been prescribed by doctors for the last 20 years to help safely adn effectively treat ED. Now, Truman is connecting guys directly to the medication they need, without the awkward office visits.</p>
                </div>
                <div className='ed-prompt-subsection'>
                    <h1 className='ed-prompt-heading'>Have Better Sex</h1>
                    <p className='ed-prompt-subheading'>Nobody wants an intimate moment to end prematurely. A return to a healthy, active sex life is possible. Who has time for bad sex?</p>
                </div>
                <div className='ed-prompt-button'>
                    {button && <Button buttonStyle='btn--primary' linkTo="https://app.trumanhealth.ca/erectile-dysfunction/patient-intake?dis=erectile">WHAT ARE YOU WAITING FOR?</Button>}
                </div>
            </div>
            <HowItWorks />
            <PreFooter />
            <Footer />
        </>
    );
}

export default ErectileDysfunction;