import React from 'react';
import '../../App.scss';
import './HairLoss.scss'
import { Hero } from '../../components/hero/Hero';
import { SecondaryTextPanel } from '../../components/text_panels/TextPanels';
import { RXCard } from '../../components/rx_cards/RXCard';
import { Footer } from '../../components/footer/Footer';
import { Button } from '../../components/buttons/Button';
import PreFooter from '../../components/prefooter/PreFooter';

function HairLoss() {
    return (
        <div className='hair-loss'>
            <Hero
                heading="Give your barber a workout."
                subheading="The best time to do something about hair loss is before it’s gone. Order hair loss meds online for home delivery."
                bannerImage="hairloss_banner" 
                bannerLink="https://app.trumanhealth.ca/hairloss/patient-intake?dis=hairloss"/>
            <div className='rx-cards-container'>
                <RXCard
                    drugImage="rx_image"
                    drugName="Finasteride + minoxidil"
                    drugDescription="Maximize your success with a combination of finasteride and minoxidil. Applied directly to the scalp."
                    drugUsage="Daily"
                    drugStrength="5%+0.25mg foam"
                    drugPrice="$93/month"
                    cardLink="https://app.trumanhealth.ca/hairloss/patient-intake?dis=hairloss"
                />
                <RXCard
                    drugImage="rx_image"
                    drugName="Finasteride"
                    drugDescription="A tablet that maintains your hair and prevents further hair from falling out. Works by tackling DHT at the hair follicles"
                    drugUsage="Daily"
                    drugStrength="1mg pill"
                    drugPrice="$53/month"
                    cardLink="https://app.trumanhealth.ca/hairloss/patient-intake?dis=hairloss"
                />
                <RXCard
                    drugImage="rx_image"
                    drugName="Rogaine (minoxidil)"
                    drugDescription="Rogaine targets the follicles directly and promotes growth. Applied directly to the scalp daily."
                    drugUsage="Daily"
                    drugStrength="5% foam"
                    drugPrice="$47/month"
                    cardLink="https://app.trumanhealth.ca/hairloss/patient-intake?dis=hairloss"
                />
            </div>
            <div className='text-panel-container'>
                <SecondaryTextPanel
                    heading="Signs you're losing hair"
                    paragraph="Are you seeing hairs on your towel or pillow? Is your forehead looking larger? Don’t wait to act."
                    buttonLabel="I'M LOSING HAIR"
                    image="hairloss_panel_img_one"
                    altText="Smiling Man"
                    direction="left"
                    panelLink="https://app.trumanhealth.ca/hairloss/patient-intake?dis=hairloss"
                />
                <SecondaryTextPanel
                    heading="The science on hair loss treatment is conclusive"
                    paragraph="Most men using finasteride (daily pill) or minoxidil (applied foam) maintain their hair and regrow it. For best results, both are recommended. Consistency is critical."
                    buttonLabel="LET'S GET TREATMENT"
                    image="hairloss_panel_img_two"
                    altText="Smiling man"
                    direction="right"
                    panelLink="https://app.trumanhealth.ca/hairloss/patient-intake?dis=hairloss"
                />
                <SecondaryTextPanel
                    heading="Love looking in the mirror again"
                    paragraph="If being bald doesn't help you feel your best, we can help. Remember, the best time to start treating your hair loss is before it’s gone."
                    buttonLabel="FIND MY TREATMENT"
                    image="hairloss_panel_img_three"
                    altText="Man looking in the mirror"
                    direction="left"
                    panelLink="https://app.trumanhealth.ca/hairloss/patient-intake?dis=hairloss"
                />
            </div>
            <div className='what-to-expect'>
                <div className='what-to-expect__header'>
                    <h1>What can I expect with treatment?</h1>
                    <p>Treating hair loss takes time. You might not find any visible changes within the first few months. After 3-4 months of daily use, you may notice that hair loss has slowed, stopped or that your hair has begun to grow again.</p>
                </div>
                <div className='what-to-expect__row'>
                    <div className='what-to-expect__row-column'>
                        <div className='what-to-expect__logo'>
                            <img src={require(`../../assets/what_to_expect_one.png`)} alt="hairloss graphic 1-2 months" />
                        </div>
                        <h3>1 - 2 months</h3>
                        <p>You likely won't notice any changes during this period and may even shed some more hair. This is normal during this part of the treatment process.</p>
                    </div>
                    <div className='what-to-expect__row-column'>
                        <div className='what-to-expect__logo'>
                            <img src={require(`../../assets/what_to_expect_two.png`)} alt="hairloss graphic 3-6 months" />
                        </div>
                        <h3>3 - 6 months</h3>
                        <p>At this point, you'll notice fewer hairs are falling out. You might even begin to see new growth. Taking progress pictures will help you keep track.</p>
                    </div>
                    <div className='what-to-expect__row-column'>
                        <div className='what-to-expect__logo'>
                            <img src={require(`../../assets/what_to_expect_three.png`)} alt="hairloss graphic 6+ months" />
                        </div>
                        <h3>6 months +</h3>
                        <p>By now, you should see that your hair loss as stopped completely or even reversed. Consistent treatment is key for best results and continued regrowth.</p>
                    </div>
                </div>
                <div className='what-to-expect__button'>
                    <Button buttonStyle='btn--primary' linkTo="https://app.trumanhealth.ca/hairloss/patient-intake?dis=hairloss">GET STARTED</Button>
                </div>
            </div>
            <PreFooter />
            <Footer />

        </div>
    );
}

export default HairLoss;