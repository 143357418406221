import React, { useState } from 'react';
import '../../App.scss';
import './Hero.scss';
import { Button } from '../buttons/Button';

export const Hero = ({ heading, subheading, bannerImage, bannerLink }) => {
    const [button] = useState(true);
    return (
        <div className='hero'
            style={{
                backgroundImage: `url(${require(`../../assets/${bannerImage}.jpeg`)})`
            }}>
            <h1 className='hero__heading'>{heading}</h1>
            <h1 className='hero__subheading'>{subheading}</h1>
            <div className='hero__button'>
                {button && <Button linkTo={bannerLink} buttonStyle='btn--primary'>Start Online Visit</Button>}
            </div>
        </ div >
    )
}
export default Hero;