import React, { useEffect, useState } from 'react';
import '../../App.scss';
import './Blog.scss';
import Posts from './posts/Posts';
import { Link, useLocation } from 'react-router-dom';
import { Footer } from '../../components/footer/Footer';
import blogData from './posts.json';
import categoriesData from './categories.json';

function Blog() {
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const { search } = useLocation();
    useEffect(() => {
        var filteredBlogData = [];
        if (search) {
            blogData.forEach(currentBlogPost => {
                if (currentBlogPost.categoryLink === search) {
                    filteredBlogData.push(currentBlogPost);
                }
            })
            setPosts(filteredBlogData);
        }
        else {
            setPosts(blogData);
        }
    }, [search]);

    useEffect(() => {
        setCategories(categoriesData);
    }, []);

    return (
        <div className='blog'>
            <div className='blog__header'>
                <p className='blog__header-preheading'>Our blog</p>
                <h1 className='blog__header-heading'>Knowledge is Power.</h1>
                <p className='blog__header-subheading'>Explore our stories and learn more about men’s health.</p>
            </div>
            <div className='blog__categories'>
                {categories.map(category => (
                    <Link to={`${category.categoryLink}`} style={{ textDecoration: "none", color: "inherit" }}>
                        <div className='blog__categories-category'>
                            {category.category}
                        </div>
                    </Link>
                ))}
            </div>
            <div className='blog__posts'>
                <Posts posts={posts} />
            </div>
            <Footer />
        </div>

    )
}

export default Blog;