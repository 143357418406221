import { NavLink } from 'react-router-dom';

export const Dropdown = ({ subnavs, dropdown, dropdownFunction }) => {
    return (
        <ul className={`dropdown ${dropdown ? "show" : ""}`}>
            {subnavs.map((subnav, index) => (
                <li key={index} className="subnav-links">
                    <NavLink className='nav-links' activeClassName="active" to={subnav.url}
                        onClick={dropdownFunction}>{subnav.title}
                    </NavLink>
                </li>
            ))}
        </ul>
    );
};

// export default Dropdown;
