export const NavbarItemsData = [
    {
        title: 'Erectile Dyfunction',
        url: '/erectile-dysfunction',
    },
    {
        title: 'Hair Loss',
        url: '/hair-loss',
    },
    {
        title: 'Smoking Cessation',
        url: '/smoking-cessation',
    },
    {
        title: 'Login',
        url: 'https://app.trumanhealth.ca/login',
    },
    {
        title: 'Learn More',
        url: "/active",
        subnav: [
            {
                title: 'About Us',
                url: '/about',
            },
            {
                title: 'Our Blog',
                url: '/blog',
            },
        ]
    },
];