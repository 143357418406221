import React, { useEffect, useState } from 'react';
import '../../../App.scss';
import './SinglePost.scss';
import { useLocation } from 'react-router-dom';
import { Footer } from '../../../components/footer/Footer';
import MDEditor from '@uiw/react-md-editor';
import blogData from '../posts.json';

function SinglePost() {
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const [post, setPost] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        blogData.forEach(currentBlogPost => {
            if (currentBlogPost._id === path) {
                setPost(currentBlogPost);
                setTitle(currentBlogPost.title);
                setDescription(currentBlogPost.description);
            }
        })
    }, [path]);

    return (
        <>

            <div className='single-post'>
                {post.image && (
                    <img className='single-post__image' src={require(`../../../blogImages/${post.image}`)} alt="blog post" />
                )}
                <h1 className='single-post__heading'>
                    {title}
                </h1>
                <MDEditor.Markdown source={description} style={{ backgroundColor: "#f5f9fd", color: "#333", fontSize: "18px" }} />
            </div>
            <Footer />
        </>

    )
}

export default SinglePost; 