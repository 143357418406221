import React, { useState } from 'react';
import '../../App.scss';
import './HowItWorks.scss';

export const HowItWorks = () => {

    const tabs = new Map([
        ['tab-one', { heading: 'Tell us about your health', info: 'All on our secure digital platform. No more awkward doctor and pharmacy visits.' }],
        ['tab-two', { heading: 'Get your medication', info: 'Once prescribed, we offer free, discreet shipping for our medication.' }],
        ['tab-three', { heading: 'Talk to our healthcare experts', info: 'Our healthcare experts are always available by email, phone, or video chat.' }],
    ]);
    const [active, setActive] = useState('tab-one');


    return (
        <div className='how-it-works-container'>
            <h1 className='how-it-works-heading'>How it Works</h1>
            <div className='how-it-works'>
                <div className='how-it-works__tab'>

                    {[...tabs].map(([key, value]) => (
                        <div key={key} active={active === key} onClick={() => setActive(key)} className={`how-it-works__tab-section ${active === key ? "active" : ""}`}>
                            <h2 className='how-it-works__tab-section-heading'>
                                {value.heading}
                            </h2>
                            <p className='how-it-works__tab-section-info'>
                                {value.info}
                            </p>
                        </div>
                    ))}
                </div>
                <div className='how-it-works__tab-image'>
                    <img src={require(`../../assets/${active}.jpeg`)} alt='Truman' />
                </div>

            </div>
        </div>
    )
}
export default HowItWorks;