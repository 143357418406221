import React from 'react';
import '../../App.scss';
import './GeneralPages.scss';
import { Footer } from '../../components/footer/Footer';

function ShippingAndRefundPolicy() {
    return (
        <>
            <div className='shipping-and-refund'>
                <div className='shipping-and-refund__heading'>Shipping & Refund Policy</div>
                <div>
                    <h3>Shipping</h3>
                    <p>Please allow 3-5 business days for delivery. We ship out of Ontario, customers further outside from Ontario should expect deliveries closer to 5 business days.</p>
                    <p>With the ongoing pandemic, there are shipping delays globally. Sometimes customers should expect delays due to shipping volume, however we work to get shipments out as soon as possible.</p>
                </div>
                <div>
                    <h3>Refund Policy</h3>
                    <p>Since our subscriptions can be cancelled anytime by emailing hello@trumanhealth.com, you can let us know before your package is setn out and we will refund you. If your package is already shipped, please contact customer support and we will work with you to get a resolution.</p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ShippingAndRefundPolicy;