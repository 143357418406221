import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.scss';
// import { Button } from '../buttons/Button';
import { NavbarItemsData } from './NavbarItemsData';
import { Dropdown } from './Dropdown';

function Navbar() {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const [dropdown, setDropdown] = useState(false);
    const [dropdown2, setDropdown2] = useState(false);

    let catMenu = useRef(null)
    let refMenu = useRef(null);
    let dropMenu = useRef(null);

    // const handleClick = (e) => {
    //     if (!dropdown && !dropdown3 && !dropMenu.current?.contains(e.target)) {
    //         setClick(false)
    //     }
    // }
    const closeMobileMenu = () => setClick(false);
    const closeDropddown = () => setDropdown((prev) => !prev);

    const closeMobileMenuAndDropdown = () => {
        closeMobileMenu();
        setDropdown2((prev) => !prev);
    }
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        }
        else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton()
    }, [])


    const closeOpenDropdowns = (e) => {
        if (dropdown && !refMenu.current?.contains(e.target)) {
            setDropdown(false);
        }
        if (dropdown2 && !catMenu.current?.contains(e.target)) {
            setDropdown2(false)
        }
    }
    document.addEventListener('mousedown', closeOpenDropdowns);
    document.addEventListener('touchstart', closeOpenDropdowns);
    // document.addEventListener('mousedown', closeMobileMenu);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <NavLink to="/" className='navbar-logo' activeClassName="active" onClick={closeMobileMenu}>
                        <img src={require('../../assets/truman_logo_dark.png')} alt='Truman' />
                    </NavLink>
                    <div className='menu-icon' ref={dropMenu} onClick={() => setClick((prev) => !prev)}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="menu-items">
                            <NavLink to="https://app.trumanhealth.ca/erectile-dysfunction/patient-intake?dis=erectile" className='nav-links hidden-until-mobile' activeClassName="active" onClick={closeMobileMenu}>
                                Start Online Visit | ED
                            </NavLink>
                        </li>
                        <li className="menu-items">
                            <NavLink to="https://app.trumanhealth.ca/hairloss/patient-intake?dis=hairloss" className='nav-links hidden-until-mobile' activeClassName="active" onClick={closeMobileMenu}>
                                Start Online Visit | Hair Loss
                            </NavLink>
                        </li>
                        <li className="menu-items">
                            <NavLink to="https://app.trumanhealth.ca/smoking-cessation/patient-intake?dis=smoking-cessation" className='nav-links hidden-until-mobile' activeClassName="active" onClick={closeMobileMenu}>
                                Start Online Visit | Smoking Cessation
                            </NavLink>
                        </li>
                        {NavbarItemsData.map((nav, index) => {
                            return (
                                <li className="menu-items">
                                    {nav.subnav ? (
                                        <>
                                            <NavLink
                                                ref={refMenu}
                                                style={{ cursor: "pointer", border: "none" }}
                                                className='nav-links dropdown-link'
                                                aria-expanded={dropdown ? "true" : "false"}
                                                onClick={closeDropddown}>
                                                Learn More &#9662;
                                            </NavLink>
                                            <Dropdown subnavs={nav.subnav} dropdown={dropdown} dropdownFunction={closeMobileMenu} />
                                        </>
                                    ) : (
                                        <NavLink to={nav.url} key={index} className='nav-links' activeClassName="active" onClick={closeMobileMenu}>
                                            {nav.title}
                                        </NavLink>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                    <div>
                        <NavLink
                            ref={catMenu}
                            style={{ cursor: "pointer", border: "none" }}
                            className='start-online-visit nav-links hidden-mobile'
                            aria-expanded={dropdown2 ? "true" : "false"}
                            onClick={closeMobileMenuAndDropdown}>
                            Start Online Visit &#9662;
                        </NavLink>
                        <ul className={`dropdown ${dropdown2 ? "show" : ""}`}>
                            {[{
                                title: 'Erectile Dyfunction',
                                url: 'https://app.trumanhealth.ca/erectile-dysfunction/patient-intake?dis=erectile',
                            },
                            {
                                title: 'Hair Loss',
                                url: 'https://app.trumanhealth.ca/hairloss/patient-intake?dis=hairloss',
                            },
                            {
                                title: 'Smoking Cessation',
                                url: 'https://app.trumanhealth.ca/smoking-cessation/patient-intake?dis=smoking-cessation',
                            }].map((subnav, index) => (
                                <li key={index} className="subnav-links hidden-mobile">
                                    <NavLink className='nav-links hidden-mobile' activeClassName="active hidden-mobile" to={subnav.url}
                                        onClick={closeMobileMenuAndDropdown}>{subnav.title}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
            </nav >
        </>
    )
}

export default Navbar