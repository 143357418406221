import React from 'react';
import '../../App.scss';
import './Home.scss';
import { TextPanel } from '../../components/text_panels/TextPanels';
import { Footer } from '../../components/footer/Footer';
import PreFooter from '../../components/prefooter/PreFooter';
import ServicesWeOffer from '../../components/services_we_offer/ServicesWeOffer';
import Testimonials from '../../components/testimonials/Testimonials';
function Home() {

    return (
        <div className='home'>
            <div className='landing-page-hero'>
                <div className='landing-page-hero__container'>
                    <p className='landing-page-hero__pre-intro'>On demand treatment just for you</p>
                    <h1 className='landing-page-hero__intro'>Welcome to Truman.</h1>
                    <h1 className='landing-page-hero__intro'>Your trusted digital men's health clinic</h1>
                    <div className='landing-page-hero__subtitle'>
                        <p>Struggling with sex? Losing your hair?</p>
                        <p>We can help. Get started with a free online visit.</p>
                    </div>
                </div>
            </div>
            <ServicesWeOffer />
            <div className='text-panel-container home-text-panel-container'>
                <TextPanel
                    heading="Erectile Dysfunction"
                    subheading="Not being able to achieve or maintain an erection is the most common symptom of ED. With the right treatment, Truman can help you regain control."
                    paragraph="Although medical professionals may advise you on ED treatments, the final decision is still up to you. It is essential to educate yourself on ED and the available prescription medications, keeping informed on all available options."
                    buttonLabel="Learn More: ED"
                    image="ED_panel_image"
                    altText="Laughing couple"
                    direction="left"
                    panelLink="/erectile-dysfunction"
                />
                <TextPanel
                    heading="Hair Loss"
                    subheading="The best time to do something about hair loss is when you still have some. Get hair loss meds, ordered online and delivered to your door."
                    paragraph="Treating hair loss takes time. You may even shed some more hair initially, however this is a normal part of the treatment process. Staying consistent with your treatment is key. Get back to being the best you."
                    buttonLabel="Learn More: Hair Loss"
                    image="hairloss_panel_image"
                    altText="Laughing man"
                    direction="left"
                    panelLink="/hair-loss"
                />
                <TextPanel
                    heading="Smoking Cessation"
                    subheading="We provide smoking cessation solutions to get you back to your healthiest self."
                    paragraph="You've likely heard of this before, but it's worth repeating: smoking can lead to heightened risks of heart and lung disease. With the right help, you have the power to stop smoking."
                    buttonLabel="Learn More: Smoking Cessation"
                    image="smoking_cessation_panel_image"
                    altText="Cigarette being split in half"
                    direction="left"
                    panelLink="/smoking-cessation"
                />
            </div>
            <Testimonials />
            <br></br>
            <br></br>
            <PreFooter />
            <Footer />
        </div>
    );
}

export default Home;