// import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import { motion, useScroll, useSpring } from "framer-motion";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
// import useScrollSnap from "react-use-scroll-snap";
import LoadingScreen from './components/splash_screen/LoadingScreen';
import Navbar from './components/navbar/Navbar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import Home from './pages/home/Home';
import ErectileDysfunction from './pages/erectile_dysfunction/ErectileDysfuntion';
import HairLoss from './pages/hairloss/HairLoss';
import SmokingCessation from './pages/smoking_cessation/SmokingCessation';
import AboutUs from './pages/about_us/AboutUs';
import Blog from './pages/blog/Blog';
import SinglePost from './pages/blog/single_blog_post/SinglePost'
import ScrollToTop from './components/scroll_to_top/ScrollToTop';
import TermsAndConditions from './pages/general_pages/Terms&Conditions';
import ShippingAndRefundPolicy from './pages/general_pages/Shipping&RefundPolicy';
import PrivacyPolicy from './pages/general_pages/PrivacyPolicy';
import AvailableInCanada from './pages/general_pages/AvailableInCanada';

function App() {
  const { scrollYProgress: scrollYProgressTarget2 } = useScroll();
  const scaleX = useSpring(scrollYProgressTarget2, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <ScrollToTop />
      <motion.div className="progress-bar" style={{ scaleX }} />
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/erectile-dysfunction' exact element={<ErectileDysfunction />} />
        <Route path='/hair-loss' exact element={<HairLoss />} />
        <Route path='/smoking-cessation' exact element={<SmokingCessation />} />
        <Route path='/about' exact element={<AboutUs />} />
        <Route path='/blog' exact element={<Blog />} />
        <Route path='/blog/:postID' exact element={<SinglePost />} />
        <Route path='/terms' exact element={<TermsAndConditions />} />
        <Route path='/shipping-refund-policy' exact element={<ShippingAndRefundPolicy />} />
        <Route path='/privacy-policy' exact element={<PrivacyPolicy />} />
        <Route path='/available-in-canada' exact element={<AvailableInCanada />} />
      </Routes>
      <SpeedInsights />
      <Analytics />
    </Router>
  );
}

export default App;
