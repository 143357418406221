import React from 'react';
import '../../../App.scss';
import './Post.scss';
import { Link } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';

function Post({ post }) {
    return (
        <div className='post'>
            <div className='post__info'>
                <Link to={`/blog/${post._id}`} style={{ textDecoration: "none", color: "inherit" }}>
                    {post.image && (
                        <img className='post__image' src={require(`../../../blogImages/${post.image}`)} alt="blog post" />
                    )}
                    <div className='post__categories'>| {post.categories} |</div>
                    <span className='post__title'>
                        {post.title}
                    </span>
                </Link>
                <hr style={{ backgroundColor: "#e0cba6", height: "1.5px", borderWidth: 0, color: "#e0cba6" }} />

            </div>
            <p className='post__description'>
                <MDEditor.Markdown source={post.description} style={{ whiteSpace: 'pre-wrap', backgroundColor: "#f5f9fd", color: "#333", fontSize: "18px" }} />
            </p>
        </div>
    )
}

export default Post; 