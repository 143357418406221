import React from 'react';
import '../../App.scss';
import './AboutUs.scss';
import { HowItWorks } from '../../components/how_it_works/HowItWorks';
import { Footer } from '../../components/footer/Footer';
import PreFooter from '../../components/prefooter/PreFooter';
import Testimonials from '../../components/testimonials/Testimonials';
import ContactForm from '../../components/contact_form/ContactForm';
import { Button } from '../../components/buttons/Button';
function AboutUs() {
    return (
        <>
            <div className='hero'
                style={{
                    backgroundImage: `url(${require(`../../assets/about_us_banner.jpeg`)})`
                }}>
                <h1 className='hero__heading'>About Us</h1>
                <h1 className='hero__subheading'>Men's health is a tricky thing, we've been told to toughen up when we feel pain, but that's an outdated notion we're challenging.</h1>
            </ div >

            <div className='about-us-subsection'>
                <div className='about-us-subsection__image'>
                    <img className='text-panel__image' src={require(`../../assets/about_us_subsection.jpeg`)} alt="smiling hiker" />
                </div>
                <div className='about-us-subsection__text'>
                    <div className='about-us-subsection__text-row'>
                        <h1 className='about-us-subsection__heading'>What we do</h1>
                        <p className='about-us-subsection__paragraph'>Whether it's treating ED or hair loss, our goal is to empower men with the right information and the medications they need to take charge of their own wellbeing.</p>
                    </div>
                    <div className='about-us-subsection__text-row'>
                        <h1 className='about-us-subsection__heading'>Who we are</h1>
                        <p className='about-us-subsection__paragraph'>Truman offers men better lviing through a more accessible approach to health — grounded in science and conveyed with discretion</p>
                        <p className='about-us-subsection__paragraph'>Health and Pharmacy team: ORIGINS Pharmacy - Oakville ON - (905) 847-9696</p>
                    </div>
                </div>
            </div>
            <ContactForm />
            <Testimonials />
            <HowItWorks />
            <PreFooter />
            <Footer />
        </>
    );
}

export default AboutUs;