import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import "./ServicesWeOffer.scss";

const data = [
    {
        id: 1,
        text: "Tell us about your health",
        subtext: "All on our secure digital platform. No more awkward doctor and pharmacy visits.",
        url: require("../../assets/1.jpg"),
    },
    {
        id: 2,
        text: "Talk to our healthcare experts",
        subtext: "Our healthcare experts are always available by email, phone or video chat.",
        url: require("../../assets/2.jpg"),
    },
    {
        id: 3,
        text: "Get your medication",
        subtext: "Once prescribed, we offer free, discrete shipping for your medication.",
        url: require('../../assets/3.jpg'),
    },
];

function Images({ id, text, url, subtext }) {
    const isMobile = window.innerWidth < 768;
    const targetRef1 = useRef(null);
    const { scrollYProgress: scrollYProgressTarget1 } = useScroll({ target: targetRef1 });
    const y = useTransform(scrollYProgressTarget1, [0, 1], [-100, 100]);
    return (
        <section className={`services-we-offer-section panel-${id}`}>
            <div ref={targetRef1}>
                <img src={url} alt={text} />
            </div>
            {!isMobile ? (
                <div className="services-we-offer-section__text">
                    <motion.h2 style={{ y }}>{text}</motion.h2>
                    <motion.h3 style={{ y }}>{subtext}</motion.h3>
                </div>) : (<div className="services-we-offer-section__text">
                    <h2>{text}</h2>
                    <h3>{subtext}</h3>
                </div>)}
        </section>
    );
}

export default function ServicesWeOffer() {
    return (
        <div className="services-we-offer-container">
            <div className="services-we-offer">
                {data.map((img) => (
                    <Images key={img.id} id={img.id} text={img.text} subtext={img.subtext} url={img.url} />
                ))}
            </div>
        </div>



    );
}