import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../App.scss';
import './Testimonials.scss';


export const Testimonials = () => {
    const settings = {
        dots: false,
        speed: 1000,
        infinite: true,
        slidesToShow: 4,
        autoplay: true,
        autoplaySpeed: 3000,
        swipeToSlide: true,
        slidesToScroll: 1,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 430,
                settings: {
                    slidesToShow: 1,
                    dots: true,

                }
            }
        ]
    };

    return (
        <div className="testimonials-slider">
            <div className='testimonials-slider__intro'>What our customers have to say</div>
            <Slider {...settings} className='testimonials-slider__row' >
                <a href="https://www.reviews.io/company-review/store/truman-health/10253440" target='_blank' rel="noopener noreferrer">
                    <div className='testimonials-slider__card'>
                        <div className='testimonials-slider__card-stars'>
                            <i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i>
                        </div>
                        <div className='testimonials-slider__card-review'>
                            I have been a customer of Truman Health for almost 2 years.  Have always found them to be of great help and very courteous.  Cannot go wrong when dealing with truman health
                        </div>
                        <div className='testimonials-slider__card-reviewer'>
                            <div className='testimonials-slider__card-reviewer-image'><img src={require('../../assets/testimonials_slider_image1.jpeg')} alt="Verified Buyer: Archie" /></div>
                            <div className='testimonials-slider__card-reviewer-info'>
                                <div>Archie</div>
                                <div>Verified Buyer &nbsp;<i class='fas fa-check-circle' style={{ color: "#e0cba6" }}></i></div>
                            </div>
                        </div>
                    </div>
                </a>

                <a href="https://www.reviews.io/company-review/store/truman-health/10602044" target='_blank' rel="noopener noreferrer">
                    <div className='testimonials-slider__card'>
                        <div className='testimonials-slider__card-stars'>
                            <i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i>
                        </div>
                        <div className='testimonials-slider__card-review'>
                            Excellent service. The medication works great. I am waiting for my next batch arriving next week! Thanks Truman!
                        </div>
                        <div className='testimonials-slider__card-reviewer'>
                            <div className='testimonials-slider__card-reviewer-image'><img src={require('../../assets/testimonials_slider_image2.jpeg')} alt="Verified Buyer: Juan" /></div>
                            <div className='testimonials-slider__card-reviewer-info'>
                                <div>Juan</div>
                                <div>Verified Buyer &nbsp;<i class='fas fa-check-circle' style={{ color: "#e0cba6" }}></i></div>
                            </div>
                        </div>
                    </div>
                </a>

                <a href="https://www.reviews.io/company-review/store/truman-health/10602285" target='_blank' rel="noopener noreferrer">
                    <div className='testimonials-slider__card'>
                        <div className='testimonials-slider__card-stars'>
                            <i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i>
                        </div>
                        <div className='testimonials-slider__card-review'>
                            Quick and easy, what more can you ask for?
                        </div>
                        <div className='testimonials-slider__card-reviewer'>
                            <div className='testimonials-slider__card-reviewer-image'><img src={require('../../assets/testimonials_slider_image3.jpeg')} alt="Verified Buyer: David" /></div>
                            <div className='testimonials-slider__card-reviewer-info'>
                                <div>David</div>
                                <div>Verified Buyer &nbsp;<i class='fas fa-check-circle' style={{ color: "#e0cba6" }}></i></div>
                            </div>
                        </div>
                    </div>
                </a>

                <a href="https://www.reviews.io/company-review/store/truman-health/10618830" target='_blank' rel="noopener noreferrer">
                    <div className='testimonials-slider__card'>
                        <div className='testimonials-slider__card-stars'>
                            <i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i>
                        </div>
                        <div className='testimonials-slider__card-review'>
                            Great option if you can't get to a doctor.
                        </div>
                        <div className='testimonials-slider__card-reviewer'>
                            <div className='testimonials-slider__card-reviewer-image'><img src={require('../../assets/testimonials_slider_image4.jpeg')} alt="Verified Buyer: Richard" /></div>
                            <div className='testimonials-slider__card-reviewer-info'>
                                <div>Richard</div>
                                <div>Verified Buyer &nbsp;<i class='fas fa-check-circle' style={{ color: "#e0cba6" }}></i></div>
                            </div>
                        </div>
                    </div>
                </a>

                <a href="https://www.reviews.io/company-review/store/truman-health/10602628" target='_blank' rel="noopener noreferrer">
                    <div className='testimonials-slider__card'>
                        <div className='testimonials-slider__card-stars'>
                            <i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i>
                        </div>
                        <div className='testimonials-slider__card-review'>
                            Fast delivery!
                        </div>
                        <div className='testimonials-slider__card-reviewer'>
                            <div className='testimonials-slider__card-reviewer-image'><img src={require('../../assets/testimonials_slider_image5.jpeg')} alt="Verified Buyer: Thomas" /></div>
                            <div className='testimonials-slider__card-reviewer-info'>
                                <div>Thomas</div>
                                <div>Verified Buyer &nbsp;<i class='fas fa-check-circle' style={{ color: "#e0cba6" }}></i></div>
                            </div>
                        </div>
                    </div>
                </a>

                <a href="https://www.reviews.io/company-review/store/truman-health/10257473" target='_blank' rel="noopener noreferrer">
                    <div className='testimonials-slider__card'>
                        <div className='testimonials-slider__card-stars'>
                            <i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i>
                        </div>
                        <div className='testimonials-slider__card-review'>
                            Really good service. I ordered my pills and they arrived quickly. The medication works well. Will definitely buy again from Truman.
                        </div>
                        <div className='testimonials-slider__card-reviewer'>
                            <div className='testimonials-slider__card-reviewer-image'><img src={require('../../assets/testimonials_slider_image6.jpeg')} alt="Verified Buyer: Peter" /></div>
                            <div className='testimonials-slider__card-reviewer-info'>
                                <div>Peter</div>
                                <div>Verified Buyer &nbsp;<i class='fas fa-check-circle' style={{ color: "#e0cba6" }}></i></div>
                            </div>
                        </div>
                    </div>
                </a>
                <a href="https://www.reviews.io/company-review/store/truman-health/10603525" target='_blank' rel="noopener noreferrer">
                    <div className='testimonials-slider__card'>
                        <div className='testimonials-slider__card-stars'>
                            <i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i><i class='fas fa-star' style={{ color: "#e0cba6" }}></i>
                        </div>
                        <div className='testimonials-slider__card-review'>
                            Truman is a very helpful service. The customer service is excellent. I would highly recommend Truman.
                        </div>
                        <div className='testimonials-slider__card-reviewer'>
                            <div className='testimonials-slider__card-reviewer-image'><img src={require('../../assets/testimonials_slider_image6.jpeg')} alt="Verified Buyer: Scott" /></div>
                            <div className='testimonials-slider__card-reviewer-info'>
                                <div>Scott</div>
                                <div>Verified Buyer &nbsp;<i class='fas fa-check-circle' style={{ color: "#e0cba6" }}></i></div>
                            </div>
                        </div>
                    </div>
                </a>
            </Slider>
            <div class="testimonials-slider__waves">
                <div class="testimonials-slider__waves-layer"></div>
                <div class="testimonials-slider__waves-layer"></div>
            </div>
            <div className='testimonials-slider__ending'></div>
        </div>

    )
}

export default Testimonials;