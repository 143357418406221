import React from 'react';
import '../../App.scss';
import './RXCard.scss';
import { Button } from '../buttons/Button';

export const RXCard = ({ drugImage, drugName, drugDescription, drugUsage, drugStrength, drugPrice, cardLink }) => {
    return (
        <>
            <div className='rx-card'>
                <div className='rx-card__image'>
                    <img src={require(`../../assets/${drugImage}.png`)} alt="Truman health discrete packaging option"></img>
                </div>
                <div className='rx-card__primary-info'>
                    <h1>{drugName}</h1>
                    <p>{drugDescription}</p>
                </div>
                <div className='rx-card__divider'></div>
                <div className='rx-card__secondary-info'>
                    <div className='rx-card__secondary-info-usage'>
                        <h4>Usage</h4>
                        <p>{drugUsage}</p>
                    </div>
                    <div className='rx-card__secondary-info-strength'>
                        <h4>Strength Available</h4>
                        <p>{drugStrength}</p>
                    </div>
                </div>
                <div className='rx-card__button'>
                    <Button buttonStyle='btn--primary' linkTo={cardLink}>Start Now  |  {drugPrice}</Button>
                </div>
            </div>
        </>
    )
}

export default RXCard;