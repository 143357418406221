import React from 'react';
import '../../App.scss';
import './TextPanels.scss';
import { Button } from '../buttons/Button';
import { ParallaxBanner } from "react-scroll-parallax";

export const TextPanel = ({ heading, subheading, paragraph, buttonLabel, image, altText, direction, panelLink }) => {
    const containerClass = `text-panel__${direction}`
    return (
        <div className={containerClass}>
            <div className='text-panel__text-container'>
                <div className='text-panel__accent-line'></div>
                <h1 className='text-panel__heading'>{heading}</h1>
                <h2 className='text-panel__subheading'>{subheading}</h2>
                <p className='text-panel__paragraph'>{paragraph}</p>
                <div className='text-panel__button'>
                    <Button buttonStyle='btn--primary' linkTo={panelLink}>{buttonLabel}</Button>
                </div>
            </div>
            <ParallaxBanner
                layers={[{ image: require(`../../assets/${image}.jpeg`), speed: (direction === "left") ? 10 : -10 }]}
                className="text-panel__image-container aspect-[2/1]"
            />
        </div>
    )
}

export const SecondaryTextPanel = ({ heading, paragraph, buttonLabel, image, altText, direction, panelLink }) => {
    const containerClass = `secondary-text-panel__${direction}`

    return (
        <div className={containerClass}>
            <div className='secondary-text-panel__text-container'>
                <div className='secondary-text-panel__accent-line'></div>
                <h1 className='secondary-text-panel__heading'>{heading}</h1>
                <p className='secondary-text-panel__paragraph'>{paragraph}</p>
                <div className='secondary-text-panel__button'>
                    <Button buttonStyle='btn--primary' linkTo={panelLink}>{buttonLabel}</Button>
                </div>
            </div>
            <div className='secondary-text-panel__image-container'>
                <ParallaxBanner
                    layers={[{ image: require(`../../assets/${image}.jpeg`), speed: (direction === "left") ? 5 : -5 }]}
                    className="secondary-text-panel__image-container aspect-[2/1]"
                />
                {/* <Parallax>
                        <img src={require(`../../assets/${image}.jpeg`)} alt={altText} />
                    </Parallax> */}

            </div>
        </div>
    )
}
const textpanels = { TextPanel, SecondaryTextPanel };
export default textpanels;